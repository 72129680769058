body, html {
    font-family: Titillium Web, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #1C2024;
    font-size: 62.5%;
    line-height: 1.5;
    font-weight: normal;
}

.second-row {
    background: #f5f5f0;
    line-height: 0%;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: inline-flex;
    width: 100%;
}

.box-title {
    width: 69%;
}

.container-live .box-title p {
    font-size: 2.2rem;
    margin-top: 6px;
    margin-bottom: 10px;
    line-height: 1.1;
}

.inmlive {
    margin: 8px 1px 2px;
    height: 21px;
    width: 100px;
    background-repeat: no-repeat;
    transition: opacity, 0.4s, ease, 0.25s;
}

.logo {
  animation: App-logo-spin infinite 20s linear;
  height: 6.2em;
}

.powered {
    display: inline-flex;
}

.powered p {
	margin-top: 20%;
	font-size: 10px;
	display: inline-flex;
}

.powered a {
    font-size: small;
}

.disabled {
	display: none;
}

.p-tabmenu-nav .p-menuitem-text {
    line-height: 1.7 !important;
}