.little-sub-header {
	width: 100%;
}

.little-sub-header .p-datatable-thead {
	display: none !important;
}

.little-sub-header .p-datatable-tfoot td {
	border: none !important;
	text-align: left !important;
	padding: 10px 0px 10px !important;
	font-weight: 400 !important;
}

.little-sub-header .p-datatable-tbody > tr > td > .p-column-title {
    display: contents !important;
}