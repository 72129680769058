.referendum-sub-header .p-datatable-thead {
	display: none !important;
}

.referendum-sub-header .p-datatable-tfoot td {
	border: none !important;
	text-align: left !important;
	padding: 10px 0px 10px !important;
	font-weight: 400 !important;
}

.referendum-table .party-images {
	position: absolute;
	left: 130%;
	width: 100%;
}

.referendum-table .border-images {
	position: relative;
	bottom: 25px;
}

.referendum-table .p-datatable-tfoot td {
	border: none !important;
	text-align: left !important;
	padding: 10px 0px 10px !important;
	font-weight: 400 !important;
}

body .referendum-table .p-datatable-footer {
    background-color: white
}