.my-content {
	position: absolute;
	margin-left: 5%;
	margin-top: 30px;
	width: 90%;
}

#headEnti {
    font-size: 15px;
}

.tableContent {
    position: relative;
    bottom: 3%;
}

.p-datatable .p-datatable-tbody > tr > td {
    border: none !important;
}

.p-datatable-footer {
	border: none !important;
}

.p-datatable-tbody > tr > td {
	padding: inherit !important;
}

.footer-value {
	font-weight: 400;
}

.candidates-button {
	color: #333333 !important;
	padding: 0 !important;
	background-color: transparent !important;
	border: none !important;
}

.candidates-button:hover {
	color: red !important;
}