#btnSearch {
    position: relative;
    width: 28.5px;
    height: 34px;
    color: black;
    border: none;
    background-color: white;
    pointer-events: none;
}

.searchsites {
	padding-bottom: 10px;
}

.chooseregion {
	padding-bottom: 10px;
	padding-right: 6px;
	display: grid;
}

.chooseprovince {
	padding-bottom: 10px;
	display: grid;
}

.choosecity {
	padding-bottom: 10px;
	display: grid;
}

label {
	font-size: 15px;
}

.choose {
	min-width: 10.5em !important;
}

.city {
	min-width: 22em !important;
}

.col-fixed {
	padding-top: 1.2%;
}

.p-dropdown .p-inputtext {
    font-size: 11px !important;
}

.p-autocomplete .p-autocomplete-input {
	padding: 0.429em;
	width: 280px;
}

.vote-tabvotes {
	float: right;
    position: relative;
    bottom: 40px;
    left: 300px;
}