.biggernogroup-sub-header {
	width: 100%;
}

.biggernogroup-sub-header .p-datatable-thead {
	display: none !important;
}

.biggernogroup-sub-header .p-datatable-tfoot td {
	border: none !important;
	text-align: left !important;
	padding: 10px 0px 10px !important;
	font-weight: 400 !important;
}

.biggernogroup-table .padding-name {
	padding: 0.429em 1em !important;
}

.biggernogroup-table .p-datatable-tfoot > tr > td > span {
	padding: 0em 0em 0.8em 1em !important;
}

.biggernogroup-sub-header .p-datatable-tbody > tr > td > .p-column-title {
    display: contents !important;
}